<template>
  <div v-if="getPaymentLink" class="d-flex align-items-center justify-content-center mt-20">
    <!--begin::Container-->
    <div class="container">
      <div class="row">
        <div class="mx-auto col-lg-6">
          <b-overlay :show="payment_link.status.id == 2" rounded="sm">
            <template #overlay>
              <div class="text-center">
                <lottie-player
                  autoplay
                  mode="normal"
                  src="https://assets3.lottiefiles.com/packages/lf20_y2hxPc.json"
                ></lottie-player>
                <span class="font-size-h4 font-weight-bold">Essa fatura já se encontra paga!</span>
                <div class="mt-3">
                  <router-link v-slot="{ href }" custom to="/">
                    <a class="btn btn-primary" :href="href" role="button">Voltar</a>
                  </router-link>
                </div>
              </div>
            </template>
            <!-- begin::Card-->
            <div class="card card-custom overflow-hidden">
              <div class="card-body p-0">
                <!-- begin: Invoice-->
                <!-- begin: Invoice header-->
                <div
                  class="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-15 px-md-0"
                  :style="{
                    backgroundImage: `linear-gradient(to top, rgba(23, 177, 198, 0.6) 50%, rgba(151, 236, 218, 0.1) 90%), url('/media/bg/bg-5.jpg')`
                  }"
                >
                  <div class="col-md-9">
                    <div
                      class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row align-items-center"
                    >
                      <h1 class="text-white font-weight-boldest mb-10 d-flex flex-column">
                        LINK DE PAGAMENTO
                        <small>#{{ $route.params.id }}</small>
                      </h1>

                      <div class="d-flex flex-column align-items-md-end px-0">
                        <!--begin::Logo-->
                        <a href="#" class="mb-5">
                          <img src="/media/logos/logo-with-name-black-vertical.png" style="width: 100px" />
                        </a>
                        <!--end::Logo-->
                      </div>
                    </div>
                    <div class="border-bottom w-100 opacity-20"></div>
                    <div class="d-flex justify-content-between text-white pt-6">
                      <div class="d-flex flex-column flex-root">
                        <span class="font-weight-bolde mb-2r">DATA</span>
                        <span class="opacity-70">
                          {{ $moment(payment_link.created_at).format('DD/MM/YYYY hh:mm:ss') }}
                        </span>
                      </div>
                      <div class="d-flex flex-column flex-root">
                        <span class="font-weight-bolder mb-2">NOME DO RECEBEDOR</span>
                        <span class="opacity-70">{{ payment_link.customer.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end: Invoice header-->
                <!-- begin: Invoice body-->
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                  <div class="col-md-9">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="pl-0 font-weight-bold text-muted text-uppercase">Descrição</th>
                            <th class="text-right pr-0 font-weight-bold text-muted text-uppercase">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="font-weight-boldest font-size-lg">
                            <td class="pl-0 pt-7">{{ payment_link.title }}</td>
                            <td class="text-right pt-7">{{ payment_link.value | currencyReal() }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end: Invoice body-->
                <!-- begin: Invoice footer-->
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                  <div class="col-md-9">
                    <div class="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                      <div class="d-flex flex-column mb-10 mb-md-0"></div>
                      <div class="d-flex flex-column text-md-right">
                        <span class="font-size-lg font-weight-bolder mb-1">TOTAL DA FATURA</span>
                        <span class="font-size-h2 font-weight-boldest text-danger mb-1">
                          {{ payment_link.value | currencyReal() }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end: Invoice footer-->
                <!-- begin: Invoice action-->

                <div v-if="payment_link.status.id == 1" class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                  <div class="col-lg-12 d-flex align-items-center justify-content-center">
                    <div class="d-flex justify-content-between">
                      <div>
                        <div v-if="!isAuthenticated">
                          <button
                            type="button"
                            class="btn btn-light-primary font-weight-bold"
                            @click="payWithSnailcoin"
                          >
                            Pagar com SnailCoin
                          </button>
                        </div>
                        <div v-else>
                          <button
                            v-if="+currentWalletSnail.balance > +payment_link.value"
                            ref="payWithSnailcoin"
                            :disabled="isSubmitting"
                            type="button"
                            class="btn font-weight-bold btn-success"
                            @click="payWithSnailcoin"
                          >
                            <span class="badge mb-2 badge-dark">
                              Saldo disponível: {{ currentWalletSnail.balance | currencySnail() }}
                            </span>
                            <br />
                            Pagar com SnailCoin
                          </button>

                          <span v-else v-b-tooltip.hover title="Saldo insuficiente">
                            <button type="button" class="btn font-weight-bold btn-light-danger" disabled>
                              Pagar com SnailCoin
                            </button>
                          </span>
                        </div>
                      </div>
                      <!-- <button type="button" class="btn btn-primary font-weight-bold" disabled>Pagar com PIX</button> -->
                    </div>
                  </div>
                </div>
                <!-- end: Invoice action-->
                <!-- end: Invoice-->
              </div>
            </div>
            <!-- end::Card-->
          </b-overlay>
        </div>
      </div>
    </div>
    <!--end::Container-->
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentLink',
  metaInfo: {
    titleTemplate: '%s | Link de Pagamento',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [{ property: 'og:title', content: 'Pagamento de Fatura' }]
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      getPaymentLink: null
    };
  },
  computed: {
    ...mapGetters(['payment_link', 'isAuthenticated', 'currentWalletSnail'])
  },
  async mounted() {
    this.isLoading = true;
    await this.$store
      .dispatch('GET_PAYMENT_LINK', this.$route.params.id)
      .then(() => {
        this.getPaymentLink = this.$store.state.payment_link;
      })
      .catch((error) => {
        this.$swal({
          title: error,
          icon: 'error'
        }).then(function () {
          window.location = '/';
        });
      });
    this.isLoading = false;
  },
  methods: {
    async payWithSnailcoin() {
      if (!this.isAuthenticated) {
        const frompath = window.location.pathname;
        this.$router.push({ name: 'login', query: { redirect: frompath } });
      } else {
        this.$swal({
          title: 'Você tem certeza que deseja pagar com seu saldo?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2e9e01',
          confirmButtonText: 'Sim, quero pagar!',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          this.isSubmitting = true;
          if (result.isConfirmed) {
            const payWithSnailcoin = this.$refs['payWithSnailcoin'];
            payWithSnailcoin.classList.add('spinner', 'spinner-light', 'spinner-right');

            // send register request
            await this.$store
              .dispatch('PAY_PAYMENT_LINK_SNAILCOIN', this.$route.params.id)
              .then((response) => {
                this.$swal({
                  title: response.success,
                  icon: 'success'
                });

                payWithSnailcoin.classList.remove('spinner', 'spinner-light', 'spinner-right');
                this.$store.dispatch('GET_PAYMENT_LINK', this.$route.params.id);
              })
              .catch((error) => {
                this.$swal({
                  title: error,
                  icon: 'error'
                });
                payWithSnailcoin.classList.remove('spinner', 'spinner-light', 'spinner-right');
              });
          }
          this.isSubmitting = false;
        });
      }
    }
  }
};
</script>
